import { config } from "@gluestack-ui/config";
import { Box, GluestackUIProvider, Text } from "@gluestack-ui/themed";
import Home from "./home";

import React, {useCallback, useContext, useEffect, useState} from 'react';
import Login from './pages/login';
import {AuthContext} from './context/AuthContext';
import Spinner from './componentes/Spinner';

function Main() {


  const authContext = useContext(AuthContext);
  const [status, setStatus] = useState('loading');

  const loadJWT = useCallback(async () => {
    try {
      authContext.setAuthState({
        accessToken: null,
        refreshToken:  null,
        authenticated:  false,
      });
      setStatus('success');
    } catch (error) {
      setStatus('error');
      authContext.setAuthState({
        accessToken: null,
        refreshToken: null,
        authenticated: false,
      });
    }
  }, []);

  useEffect(() => {
    loadJWT();
  }, [loadJWT]);
 

  if (status === 'loading') {
    return <Spinner />;
  }

  if (authContext?.authState?.authenticated === false) {
    return <Login />;
  } else {
    return (
      <Home />
    );
  }
};

export default Main;

