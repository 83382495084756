import Gradient from '../../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import React, { useContext, useState, useEffect, useRef } from 'react';
import UnidadCard from '../../componentes/UnidadCard';
import AmenitieCard from '../../componentes/AmenitieCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../../context/AuthContext';
import { AxiosContext } from '../../context/AxiosContext';
import baseConfig from '../../config';
import SelectDropdown from 'react-native-select-dropdown';
import Calendar from '../../assets/Icons/Calendar';
import FeatureCard from '../../componentes/FeatureCard';
import MenuIcon from '../../assets/Icons/MenuIcon';

import { config } from "@gluestack-ui/config";
import { GluestackUIProvider } from '@gluestack-ui/themed';

import {
    Box, Text,
    FormControl, FormControlLabel, FormControlLabelText, FormControlError, FormControlErrorText, FormControlErrorIcon, FormControlHelper,
    FormControlHelperText, AlertCircleIcon, ButtonText, ButtonIcon, AddIcon, Button, UnlockIcon, TrashIcon,
    Select, SelectTrigger, SelectInput, SelectIcon, SelectPortal, SelectBackdrop, SelectContent, SelectDragIndicatorWrapper, SelectDragIndicator, SelectItem,
    ChevronDownIcon, Icon, AlertDialog, AlertDialogBackdrop, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Center,
    showAlertDialog, AlertDialogOverlay, AlertDialogDescription, AlertDialogTitle, AlertDialogAction, AlertDialogCancel, AlertDialogIcon, AlertDialogLoading,
    Heading, CloseIcon, ButtonGroup, Textarea, TextareaField, Checkbox, CheckboxLabel, CheckboxLabelText, Radio, RadioGroup, RadioLabel, RadioLabelText

} from '@gluestack-ui/themed';
import ReservaCard from '../../componentes/ReservaCard';


const Reservas = (props) => {

    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('unidad');
    }

    const [unidadData, setUnidadData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [reservasData, setReservasData] = useState([]);
    const [reservasLoaded, setReservasLoaded] = useState(false);
    const isFirstRender = useRef(true);
 

    useEffect(() => {
        loadUnidadData();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
        loadReservas();
    }, [unidadData]); // 👈️ add state variables you want to track


    const loadUnidadData = async () => {
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }

    const loadReservas = async () => {
        setLoading(true);
        try {
            let data = {
                unidad_id: unidadData.id,
            }
            
            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/misreservas';
            const response = await axiosContext.authAxios.post(url, data);

            console.log(response.data);

            if (response.data.success == false) {
                setLoading(false);
                setReservasLoaded(false);
                return;
            }

            setReservasData(response.data);
            setLoading(false);
            setReservasLoaded(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const mostrarAmenitie = async (amenitie_id) => {
        props.setAmenitieID(amenitie_id);
        props.setPage('amenitie');  
    }

    return (
        <GluestackUIProvider config={config}>
            <Box flex={1} backgroundColor="$black">
                <ScrollView
                    style={{ height: '60%' }}
                    contentContainerStyle={{ flexGrow: 1 }}
                >
                    <Box
                        position="absolute"
                        sx={{
                            '@base': {
                                h: 500,
                                w: 500,
                            },
                            '@lg': {
                                h: 700,
                                w: 700,
                            },
                        }}
                    >
                        <Gradient />
                    </Box>
                    <Box
                        height="60%"
                        sx={{
                            '@base': {
                                my: '$16',
                                mx: '$5',
                                height: '80%',
                            },
                            '@lg': {
                                my: '$24',
                                mx: '$32',
                            },
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >


                        <Box
                            justifyContent="center"
                            alignItems="center">

                            <Text color="$white" fontWeight="$normal">
                                    Mis Reservas
                            </Text>
                            
                            {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                            {loading ? null :
                                <Box
                                    justifyContent="center"
                                    alignItems="center">
                                    <Text color="$white" fontWeight="$normal" textAlign='center'>
                                        Lista de reservas para esta unidad.
                                    </Text>

                                    <Box mt={15} style={{ height: 300 }}>
                                        {reservasLoaded ? <ActivityIndicator size="large" color="#ca50b0" /> : null}
                                        <ScrollView >
                                            {reservasData?.map((reserva) => {
                                               
                                                    return (
                                                    <TouchableOpacity key={reserva.id}>
                                                    <ReservaCard reserva={reserva} />
                                                        </TouchableOpacity>
                                                     )
                                                }
                                            )}
                                            {!reservasLoaded ? null : <View>
                                                {reservasData.length ? null : <Text>No encontramos reservas vigentes para esta unidad.</Text>}
                                            </View>
                                            }
                                        </ScrollView>
                                    </Box>
                                </Box>
                            }
                        </Box>

                        <Box
                            sx={{
                                '@base': {
                                    flexDirection: 'column',
                                },
                                '@md': {
                                    flexDirection: 'row',
                                },
                            }}
                        >

                            <Button
                                size="md"
                                variant="outline"
                                action="primary"

                                title="Salir" onPress={() => volver()}>

                                <ButtonText color="$white">Volver</ButtonText>
                            </Button>

                        </Box>
                    </Box>


                </ScrollView>
            </Box>
        </GluestackUIProvider>

    );
}

export default Reservas;