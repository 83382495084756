
import { Box, GluestackUIProvider, Text, Button, ButtonText, Input, InputField, FormControlLabel, FormControlLabelText, FormControl, CalendarDaysIcon, ClockIcon } from '@gluestack-ui/themed';
import Gradient from '../../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator } from 'react-native';
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import UnidadCard from '../../componentes/UnidadCard';
import AmenitieCard from '../../componentes/AmenitieCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates'
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../../context/AuthContext';
import { AxiosContext } from '../../context/AxiosContext';
import dayjs from "dayjs";
import baseConfig from '../../config';
import SelectDropdown from 'react-native-select-dropdown';
import { config } from "@gluestack-ui/config";
import { es, registerTranslation } from 'react-native-paper-dates'
registerTranslation('es', es)

import {
    AlertDialog, AlertDialogBackdrop, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Center,
    Heading, CloseIcon, ButtonGroup, Icon

} from '@gluestack-ui/themed';




const Amenitie = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('unidad');
    }

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [locale, setLocale] = useState('es');
    const [unidadData, setUnidadData] = useState();
    const [amenitieData, setamenitieData] = useState([]);
    const [amenitieLoaded, setamenitieLoaded] = useState(false);

    const [formaPago, setFormaPago] = useState({ value: '1', nombre: 'Pagar junto a la expensa' });
    const [turnosContiguos, setTurnosContiguos] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [timeOpen, setTimeOpen] = useState(false);

    const [reservaHasta, setReservaHasta] = useState(new Date());

    const [fecha, setFecha] = useState(new Date());
    const [time, setTime] = useState(
        {
            hours: new Date().getHours(),
            minutes: new Date().getMinutes(),
        }
    );

    const [importe, setImporte] = useState('');
    const [loading, setLoading] = useState(false);

    const [loadingConsulta, setLoadingConsulta] = useState(false);
    const [consultaData, setConsultaData] = useState([]);

    const [formasPago, setFormasPago] = useState([
        { value: '0', nombre: 'Paga por otro medio' },
        { value: '1', nombre: 'Pagar junto a la expensa' }
    ]);

    //date picker
    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setFecha(params.date);
        },
        [setOpen, setFecha]
    );

    const onConfirmTime = useCallback(
        ({ hours, minutes }) => {

            //  

            setTimeOpen(false)
            setTime({ hours, minutes })
        },
        [setTimeOpen, setTime]
    )
    const onDismissTime = useCallback(() => {
        setTimeOpen(false)
    }, [setTimeOpen])


    const timeFormatter = useMemo(
        () =>
            new Intl.DateTimeFormat(locale, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: locale === 'en',
            }),
        [locale]
    )

    const formatearFecha = (fechaString) => {
        const fecha = new Date(fechaString);
        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
        const año = fecha.getFullYear();
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');

        return `${dia}/${mes}/${año} ${horas}:${minutos}`;
    };


    let timeDate = new Date()
    time.hours !== undefined && timeDate.setHours(time.hours)
    time.minutes !== undefined && timeDate.setMinutes(time.minutes)

    const onFormaPagoChange = async (key) => {
        setFormaPago(key);
    }


    useEffect(() => {
        if (amenitieData.reservas_seguidas == 0) {
            setTurnosContiguos(1);
        }
    }, [turnosContiguos]);


    const consultar = async () => {

        setLoadingConsulta(true);

        let formattedDate = dayjs(fecha)
            .hour(time.hours)
            .minute(time.minutes)
            .format('YYYY-MM-DD HH:mm');

        try {

            let data = {
                unidad_id: unidadData.id,
                amenitie_id: props.amenitieID,
                fecha_inicio: formattedDate,
                reservas_contiguas: turnosContiguos
            }

            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/consultar';
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == false) {
                setLoadingConsulta(false);
                return;
            }

            setTurnosContiguos(response.data.reservas_contiguas);
            //"2024-11-28 17:30"
            let newDate = dayjs(response.data.fecha_reserva_desde, 'YYYY-MM-DD HH:mm');
            
            let fecha = new Date(newDate.year(), newDate.month(), newDate.date(), newDate.hour(), newDate.minute());
            setFecha(fecha);
            
            setTime({
                hours: newDate.hour(),
                minutes: newDate.minute()
            })

            setConsultaData(response.data);
            setLoadingConsulta(false);
        } catch (error) {
            console.log(error);
            setLoadingConsulta(false);
        }

        setShowAlertDialog(true);
    }

    const reservar = async () => {

        setLoading(true);

        let formattedDate = dayjs(fecha)
            .hour(time.hours)
            .minute(time.minutes)
            .format('YYYY-MM-DD HH:mm');

        let data = {
            unidad_id: unidadData.id,
            amenitie_id: props.amenitieID,
            fecha_inicio: formattedDate,
            reservas_contiguas: turnosContiguos,
            forma_pago: formaPago.value,
        }

        const tenantName = await AsyncStorage.getItem('tenant');
        let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/reservar';

        try {
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.status) {
                setShowAlertDialog(false);
                toast.show('Reservado correctamente.', {
                    type: "success",
                });
            }
            else {
                setShowAlertDialog(false);
                toast.show(response.data.message, {
                    type: "warning",
                });
            }

        } catch (error) {
            toast.show('No se pudo reservar, intentalo mas tarde', {
                type: "warning",
            });
        }
        finally {
            setLoading(false);
        }
    }

    const loadUnidadData = async () => {
        const getUnidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(getUnidadData));
    }

    const loadAmenitie = async () => {
        setLoading(true);

        if (unidadData === undefined) {
            setLoading(false);
            return;
        }

        try {
            let data = {
                unidad_id: unidadData.id,
                amenitie_id: props.amenitieID
            }

            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/ver';
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == false) {
                setLoading(false);
                setamenitieLoaded(false);
                return;
            }

            if (response.data.rubro_egreso_id == 0) {
                setFormasPago(
                    [
                        { value: '0', nombre: 'Paga por otro medio' },
                    ]
                );
                setFormaPago({ value: '0', nombre: 'Paga por otro medio' });
            } else {
                [
                    { value: '0', nombre: 'Paga por otro medio' },
                    { value: '1', nombre: 'Pagar junto a la expensa' },
                ]
            }

            setamenitieData(response.data);
            setLoading(false);
            setamenitieLoaded(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };


    useEffect(() => {
        loadUnidadData();
    }, []);

    useEffect(() => {
        loadAmenitie();
    }, [unidadData]);

    return (
        <GluestackUIProvider config={config}>
            <Box flex={1} backgroundColor="$black">
                <ScrollView
                    style={{ height: '100%' }}
                    contentContainerStyle={{ flexGrow: 1 }}
                >
                    <Box
                        position="absolute"
                        sx={{
                            '@base': {
                                h: 500,
                                w: 500,
                            },
                            '@lg': {
                                h: 700,
                                w: 700,
                            },
                        }}
                    >
                        <Gradient />
                    </Box>
                    <Box
                        height="60%"
                        sx={{
                            '@base': {
                                my: '$16',
                                mx: '$5',
                                height: '80%',
                            },
                            '@lg': {
                                my: '$24',
                                mx: '$32',
                            },
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >


                        <Box
                            justifyContent="center"
                            alignItems="center">

                            <Text color="$white" fontWeight="$normal">
                                Reserva de amenities
                            </Text>

                            <UnidadCard
                                name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
                                desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
                                saldo={unidadData?.saldo}
                            />

                            {amenitieLoaded ? null :
                                <AmenitieCard amenitie={amenitieData} />

                            }


                            {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                            {loading ? null :
                                <Box
                                    justifyContent="center"
                                    alignItems="center">
                                    <Text color="$white" fontWeight="$normal" textAlign='center'>
                                        Complete los datos para realizar la reserva
                                    </Text>


                                    <Box h="$32" w="$72" mt={15}>
                                        <FormControl
                                            mt={10}
                                            size="md"
                                            isRequired={true}
                                            readOnly={true}
                                        >
                                            <FormControlLabel mb="$1">
                                                <FormControlLabelText color='$white'>Fecha de reserva</FormControlLabelText>
                                            </FormControlLabel>
                                            <Input>
                                                <InputField color='$white' type="text" value={dayjs(fecha).format("DD-MM-YYYY")} placeholder="Fecha de reserva" />
                                            </Input>

                                            <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined" variant="outline"
                                                action="primary">
                                                <Text color='$white'><CalendarDaysIcon color='$white' /> </Text>
                                            </Button>
                                        </FormControl>

                                        <FormControl
                                            mt={10}
                                            size="md"
                                            isRequired={true}
                                            readOnly={true}
                                        >
                                            <FormControlLabel mb="$1">
                                                <FormControlLabelText color='$white'>Hora de reserva</FormControlLabelText>
                                            </FormControlLabel>
                                            <Input>

                                                <InputField readOnly color='$white' type="text" value={time &&
                                                    time.hours !== undefined &&
                                                    time.minutes !== undefined
                                                    ? timeFormatter.format(timeDate)
                                                    : 'No time selected.'} placeholder="Fecha de reserva" />

                                            </Input>

                                            <Button onPress={() => setTimeOpen(true)} uppercase={false} mode="outlined" variant="outline"
                                                action="primary">
                                                <Text color='$white'><ClockIcon color='$white' /> </Text>
                                            </Button>
                                        </FormControl>


                                        <FormControl
                                            mt={10}
                                            size="md"
                                            isRequired={true}
                                        >
                                            <FormControlLabel mb="$1">
                                                <FormControlLabelText color='$white'>Turnos Contiguos (son de {amenitieData.fraccion_tiempo} minutos)</FormControlLabelText>
                                            </FormControlLabel>
                                            <Input>
                                                <InputField onChangeText={(text) => setTurnosContiguos(text)} color='$white' type="number" value={turnosContiguos} placeholder="Turnos Contiguos ej: 1" keyboardType='number-pad' />
                                            </Input>
                                        </FormControl>


                                        <FormControlLabel mb="$1">
                                            <FormControlLabelText color="$white">Forma de Pago</FormControlLabelText>
                                        </FormControlLabel>
                                        <SelectDropdown
                                            buttonStyle={{ width: 300, backgroundColor: 'rgba(255,255,255,0.1)' }}
                                            buttonTextStyle={{ color: 'white' }}
                                            data={formasPago}
                                            defaultButtonText={"Seleciona la forma de pago"}
                                            defaultValue={formaPago}
                                            onSelect={(selectedItem, index) => {
                                                onFormaPagoChange(selectedItem);
                                            }}
                                            buttonTextAfterSelection={(selectedItem, index) => {
                                                // text represented after item is selected
                                                // if data array is an array of objects then return selectedItem.property to render after item is selected
                                                return selectedItem.nombre
                                            }}
                                            rowTextForSelection={(item, index) => {
                                                // text represented for each item in dropdown
                                                // if data array is an array of objects then return item.property to represent item in dropdown
                                                return item.nombre
                                            }}
                                        />

                                        <Button
                                            mt={20}
                                            size="md"
                                            variant="outline"
                                            action="primary"
                                            title="Salir" onPress={() => consultar()}>
                                            <ButtonText color="$white">Consultar</ButtonText>
                                        </Button>

                                        <Button
                                            mt={25}
                                            size="md"
                                            variant="outline"
                                            action="primary"

                                            title="Salir" onPress={() => volver()}>

                                            <ButtonText color="$white">Volver</ButtonText>
                                        </Button>

                                    </Box>
                                </Box>
                            }

                        </Box>
                    </Box>


                    <SafeAreaProvider>
                        <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>

                            <DatePickerModal
                                locale="es"
                                mode="single"
                                visible={open}
                                onDismiss={onDismissSingle}
                                date={fecha}
                                onConfirm={onConfirmSingle}
                            />
                        </View>

                    </SafeAreaProvider>


                    <SafeAreaProvider>

                        <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>

                            <TimePickerModal
                                locale="es"
                                visible={timeOpen}
                                onDismiss={onDismissTime}
                                onConfirm={onConfirmTime}
                                hours={time.hours}
                                minutes={time.minutes}
                            />

                        </View>
                    </SafeAreaProvider>

                    <SafeAreaProvider>

                        <Center h={25} mb={10}>
                            <AlertDialog
                                isOpen={showAlertDialog}
                                onClose={() => {
                                    setShowAlertDialog(false)
                                }}
                            >
                                <AlertDialogBackdrop />
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <Heading size="lg">Confirmación de reserva</Heading>
                                        <AlertDialogCloseButton>
                                            <Icon as={CloseIcon} />
                                        </AlertDialogCloseButton>
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                        <Text size="sm">
                                            Es posible que se haya ajustado la hora de su reserva, por favor compruebe que la fecha y hora estén correctas luego confirme la reservar.
                                            {"\n"}
                                        </Text>

                                        {loadingConsulta ? <ActivityIndicator size="large" color="#ca50b0" /> :
                                            <Text>
                                                {!consultaData.status ? <Text>No se pudo completar la consulta, intentalo mas tarde,
                                                    si el error persiste, intente con otra fecha y hora.
                                                </Text> :
                                                    <Text>
                                                        {!consultaData.disponibilidad.disponible ?
                                                            <Text>Hubo un problema para la fecha y hora seleccionada.{"\n"}
                                                                Razon: {consultaData.disponibilidad.razon}
                                                            </Text> :

                                                            <Text>La fecha y hora mostrada está disponible, por favor revise los datos y confirme la reserva.
                                                                {"\n"}{"\n"}
                                                                Reserva desde: <Text bold>{formatearFecha(consultaData.disponibilidad.fechaInicio)} </Text>
                                                                {"\n"}
                                                                Reserva hasta: <Text bold>{formatearFecha(consultaData.disponibilidad.fechaFin)} </Text>
                                                                {"\n"}
                                                                Importe: <Text bold> ${consultaData.importe.importe_residente} </Text>

                                                            </Text>

                                                        }
                                                    </Text>

                                                }
                                            </Text>
                                        }


                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                        <ButtonGroup space="lg">
                                            <Button
                                                variant="outline"
                                                action="secondary"
                                                onPress={() => {
                                                    setShowAlertDialog(false)
                                                }}
                                            >
                                                <ButtonText>Cancelar</ButtonText>
                                            </Button>

                                            {loadingConsulta ? null :
                                                <>

                                                {!consultaData.status ? null :  
                                                        <>
                                                           {!consultaData.disponibilidad.disponible ? null :
                                                                <Button
                                                                    bg="$success600"
                                                                    action="negative"
                                                                    onPress={() => {
                                                                        reservar();
                                                                    }}
                                                                >
                                                                    <ButtonText>Confirmar</ButtonText>
                                                                </Button>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                        </ButtonGroup>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </Center>
                    </SafeAreaProvider>



                </ScrollView>
            </Box>
        </GluestackUIProvider>



    );
}

export default Amenitie;