import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';
import { StyleSheet, View } from 'react-native';
import dayjs from "dayjs";


const ReservaCard = ({ reserva }) => {
  return (
    <Box
      margin={5}
      flexDirection="scolumn"
      borderWidth={1}
      borderColor="$borderDark700"
      sx={{
        _web: {
          flex: 1,
        },
      }}

      rounded="$md"
      width={300}
      alignItems="center" // Add this line
    >

        <Box
        borderTopWidth={1}
        borderTopColor="$borderDark700"
        borderStartStartRadius={5}
        borderStartEndRadius={5}
        width={300}
        backgroundColor='rgba(187, 21, 21, 0.2)'
        alignItems="center" // Add this line
      >
        <Text fontSize={20} color="$white" fontWeight="500" ml="$2">
       { reserva.amenitie_sin_scope.nombre }
       </Text>
      </Box>

      <Box mt={5}>
        <View style={styles.itemContainer}>
          <Text style={[styles.fecha]}> { reserva.amenitie_sin_scope.aviso } </Text>
        </View>

        <View style={styles.itemContainer}>

          <Text style={styles.titulo}>
          Reservado a: { reserva.nombre }
          </Text>

          </View>

          <View style={styles.itemContainer}>

          <Text style={styles.titulo}>
          Desde: { reserva.fecha_inicio }
          </Text>

          </View>

          <View style={styles.itemContainer}>

          <Text style={styles.titulo}>
          Hasta: { reserva.fecha_fin }
          </Text>

          </View>

          <View style={styles.itemContainer}>

          <Text style={styles.titulo}>
          Valor: { reserva.importe }
          </Text>

          <Text style={styles.titulo}>
           Adeuda: { reserva.capital }
          </Text>


        </View>
      </Box>

    </Box>
  );
};

export default ReservaCard;


const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  titulo: {
    color: 'white',
    marginRight: 10,
  },
  fecha: {
    color: 'white',
  },
  negrita: {
    fontWeight: 'bold',
  },
});
