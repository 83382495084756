import React, { useCallback, useEffect, useState } from "react";
import { AuthProvider } from "./context/AuthContext";
import { AxiosProvider } from "./context/AxiosContext";
import { ToastProvider } from "react-native-toast-notifications";
import Main from "./main";

function App() {

  return (
      <AuthProvider>
        <AxiosProvider>
          <ToastProvider>
            <Main />
          </ToastProvider>
        </AxiosProvider>
      </AuthProvider>
  );
}


export default App;