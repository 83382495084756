import Gradient from '../../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import React, { useContext, useState, useEffect, useRef } from 'react';
import UnidadCard from '../../componentes/UnidadCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../../context/AuthContext';
import { AxiosContext } from '../../context/AxiosContext';
import baseConfig from '../../config';
import SelectDropdown from 'react-native-select-dropdown';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates'

import { Image, StyleSheet, FlatList } from 'react-native';

import Calendar from '../../componentes/Calendar'

import moment from "moment";
import { config } from "@gluestack-ui/config";
import dayjs from "dayjs";
import { es, registerTranslation } from 'react-native-paper-dates'

import { Box, GluestackUIProvider, Text, Button, ButtonText, Input, InputField, FormControlLabel, FormControlLabelText, FormControl, CalendarDaysIcon, ClockIcon } from '@gluestack-ui/themed';

import {
    AlertDialog, AlertDialogBackdrop, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Center,
    Heading, CloseIcon, ButtonGroup, Icon

} from '@gluestack-ui/themed';

registerTranslation('es', es)



const Calendario = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('amenities');
    }

    const [unidadData, setUnidadData] = useState();
    const [loading, setLoading] = useState(false);

    const [amenitiesData, setAmenitiesData] = useState([]);
    const [amenitiesLoaded, setAmenitiesLoaded] = useState(false);
    const [fecha, setFecha] = useState(new Date());
    const [calendarData, setCalendarData] = useState([]);
    
    const [selectedAmenitie, setSelectedAmenitie] = useState();

    const isFirstRender = useRef(true);
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [amenitieData, setAmenitieData] = useState([]);

    useEffect(() => {
        loadUnidadData();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
        loadAmenities();
    }, [unidadData]); // 👈️ add state variables you want to track


    const loadUnidadData = async () => {
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }

    //date picker
    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setFecha(params.date);
        },
        [setOpen, setFecha]
    );

    useEffect(() => {
        if(selectedAmenitie){
        changeAmenity(selectedAmenitie);
        }
    }, [fecha]);

    const loadAmenities = async () => {
        setLoading(true);
        try {

            let data = {
                unidad_id: unidadData.id
            }

            const tenantName = await AsyncStorage.getItem('tenant');
            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities';
            const response = await axiosContext.authAxios.post(url, data);

            if (response.data.success == false) {
                setLoading(false);
                setExpensasLoaded(false);
                return;
            }

            setAmenitiesData(response.data);
            setAmenitiesLoaded(true);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const changeAmenity = async (key) => {

        setLoading(true);
        try {
            const tenantName = await AsyncStorage.getItem('tenant');
            let dataAmenitie = {
                unidad_id: unidadData.id,
                amenitie_id: key,
            }
         
            let urlAmenitie = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/ver';
            const responseAmenitie = await axiosContext.authAxios.post(urlAmenitie, dataAmenitie);
            setAmenitieData(responseAmenitie.data);
    
            let data = {
                unidad_id: unidadData.id,
                amenitie_id: key,
                fecha: fecha
            }

            let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/amenities/calendario';
            const response = await axiosContext.authAxios.post(url, data);

            console.log(response.data);

          
            if (response.data.success == false) {
                setLoading(false);
                setExpensasLoaded(false);
                return;
            }

            setCalendarData(response.data);
            setAmenitiesLoaded(true);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    return (
        <GluestackUIProvider config={config}>
            <Box flex={1} backgroundColor="$black">
                <ScrollView
                    style={{ height: '60%' }}
                    contentContainerStyle={{ flexGrow: 1 }}
                >
                    <Box
                        position="absolute"
                        sx={{
                            '@base': {
                                h: 500,
                                w: 500,
                            },
                            '@lg': {
                                h: 700,
                                w: 700,
                            },
                        }}
                    >
                        <Gradient />
                    </Box>
                    <Box
                        height="60%"
                        sx={{
                            '@base': {
                                my: '$16',
                                mx: '$5',
                                height: '80%',
                            },
                            '@lg': {
                                my: '$24',
                                mx: '$32',
                            },
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        <Box
                            justifyContent="center"
                            alignItems="center">

                            <Text color="$white" fontWeight="$normal">
                                Calendario de Reservas
                            </Text>

                            {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                            {loading ? null :
                                <Box
                                    justifyContent="center"
                                    alignItems="center">
                                    <Text color="$white" fontWeight="$normal" textAlign='center'>
                                        Seleccione una fecha y un amenity para ver las reservas
                                    </Text>

                                    <Box mt={10}>
                                        <SelectDropdown
                                            buttonStyle={{ width: 300, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 15 }}
                                            buttonTextStyle={{ color: 'white', borderColor: 'white' }}

                                            data={amenitiesData}
                                            defaultButtonText={"Lista de Amenities"}
                                            onSelect={(selectedItem, index) => {
                                                changeAmenity(selectedItem.id);
                                                setSelectedAmenitie(selectedItem.id);
                                            }}
                                            buttonTextAfterSelection={(selectedItem, index) => {
                                                // text represented after item is selected
                                                // if data array is an array of objects then return selectedItem.property to render after item is selected
                                                return selectedItem.id
                                            }}
                                            rowTextForSelection={(item, index) => {
                                                // text represented for each item in dropdown
                                                // if data array is an array of objects then return item.property to represent item in dropdown
                                                return item.nombre
                                            }}
                                        />
                                    </Box>

                                    <FormControl
                                        mt={10}
                                        size="md"
                                        isRequired={true}
                                        readOnly={true}
                                    >
                                        <FormControlLabel mb="$1">
                                            <FormControlLabelText color='$white'>Fecha de reserva</FormControlLabelText>
                                        </FormControlLabel>
                                        <Input>
                                            <InputField color='$white' type="text" value={dayjs(fecha).format("DD-MM-YYYY")} placeholder="Fecha de reserva" />
                                        </Input>

                                        <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined" variant="outline"
                                            action="primary">
                                            <Text color='$white'><CalendarDaysIcon color='$white' /> </Text>
                                        </Button>
                                    </FormControl>

                                    <Box>
                                        <ScrollView >
                                            {calendarData.length > 0 ? 
                                            <Calendar amenitieData={amenitieData} calendarData={calendarData}></Calendar>
                                            : 
                                            <Text mt={15} color="$white" fontWeight="$normal" textAlign='center'>
                                                No hay datos para mostrar, pruebe seleccionado otro amenity o fecha.
                                            </Text>
                                            }
                                        </ScrollView>
                                    </Box>
                                </Box>
                            }
                        </Box>


                        <Box
                            sx={{
                                '@base': {
                                    flexDirection: 'column',
                                },
                                '@md': {
                                    flexDirection: 'row',
                                },
                            }}
                        >

                            <Button
                                size="md"
                                variant="outline"
                                action="primary"

                                title="Salir" onPress={() => volver()}>

                                <ButtonText color="$white">Volver</ButtonText>
                            </Button>

                        </Box>
                    </Box>

                </ScrollView>

                <SafeAreaProvider>
                    
                    <View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
                        <DatePickerModal
                            locale="es"
                            mode="single"
                            visible={open}
                            onDismiss={onDismissSingle}
                            date={fecha}
                            onConfirm={onConfirmSingle}
                        />
                    </View>

                </SafeAreaProvider>

            </Box>


        </GluestackUIProvider>

    );
}

export default Calendario;