import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { AxiosContext } from './context/AxiosContext';

import Unidades from './pages/unidades';
import Unidad from './pages/unidad';
import AvisoPago from './pages/avisoPago';
import Expensas from './pages/expensas';
import Notificaciones from './pages/notificaciones';
import Proveedores from './pages/proveedores';
import Comprobantes from './pages/comprobantes';

import Calendario from './pages/amenities/calendario';
import Reservas from './pages/amenities/reservas';
import Amenities from './pages/amenities/amenities';
import Amenitie from './pages/amenities/amenitie';

import Reglamento from './pages/reglamento';

const Home = () => {

  const [pageUnidades, setPageUnidades] = useState(true);
  const [pageUnidad, setPageUnidad] = useState(false);
  const [pageAvisoPago, setPageAvisoPago] = useState(false);
  const [pageNotificaciones, setPageNotificaciones] = useState(false);
  const [pageExpensas, setPageExpensas] = useState(false);
  const [pageProveedores, setPageProveedores] = useState(false);
  const [pageComprobantes, setPageComprobantes] = useState(false);

  const [pageCalendario, setPageCalendario] = useState(false);
  const [pageReservas, setPageReservas] = useState(false);
  const [pageAmenities, setPageAmenities] = useState(false);
  const [pageAmenitie, setPageAmenitie] = useState(false);
  const [pageReglamento, setPageReglamento] = useState(false);

  const [comprobanteLiquidacionID, setComprobanteLiquidacionID] = useState(null);

  const [amenitieID, setAmenitieID] = useState(null);
  
  const setPage = (page) => {

    setPageUnidades(false);
    setPageUnidad(false);
    setPageAvisoPago(false);
    setPageNotificaciones(false);
    setPageExpensas(false);
    setPageProveedores(false);
    setPageComprobantes(false);
    setPageCalendario(false);
    setPageReservas(false);
    setPageAmenities(false);
    setPageAmenitie(false);
    setPageReglamento(false);

    switch (page) {
      case 'unidades':
        setPageUnidades(true);
        break;
      case 'unidad':
        setPageUnidad(true);
        break;
      case 'avisoPago':
        setPageAvisoPago(true);
        break;
      case 'notificaciones':
        setPageNotificaciones(true);
        break;
      case 'expensas':
        setPageExpensas(true);
        break;
      case 'proveedores':
        setPageProveedores(true);
        break;
      case 'comprobantes':
        setPageComprobantes(true);
        break;
        case 'calendario':
        setPageCalendario(true);
        break;
        case 'reservas':
        setPageReservas(true);
        break;
        case 'amenities':
        setPageAmenities(true);
        break;
        case 'amenitie':
        setPageAmenitie(true);
        break;
      case 'reglamento':
        setPageReglamento(true);
        break;
      default:
        setPageUnidades(true);
        break;
    }
  }


  if (pageUnidades) {
    return (<Unidades setPage={setPage} />)
  }
  if (pageUnidad) {
    return (<Unidad setPage={setPage} />)
  }
  if (pageAvisoPago) {
    return (<AvisoPago setPage={setPage} />)
  }
  if (pageNotificaciones) {
    return (<Notificaciones setPage={setPage} />)
  }
  if (pageExpensas) {
    return (<Expensas setPage={setPage} />)
  }
  if (pageProveedores) {
    return (<Proveedores setPage={setPage} setComprobanteLiquidacionID={setComprobanteLiquidacionID} />)
  }
  if (pageComprobantes) {
    return (<Comprobantes setPage={setPage} comprobanteLiquidacionID={comprobanteLiquidacionID} />)
  }

  if (pageCalendario) {
    return (<Calendario setPage={setPage} />)
  }
  if (pageReservas) {
    return (<Reservas setPage={setPage} />)
  }
  if (pageAmenities) {
    return (<Amenities setPage={setPage} setAmenitieID={setAmenitieID} />)
  }
  if (pageAmenitie) {
    return (<Amenitie setPage={setPage} amenitieID={amenitieID} />)
  }
  if (pageReglamento) {
    return (<Reglamento setPage={setPage} />)
  }

};


export default Home;