import React from "react";
import { Svg, Path } from "react-native-svg";
import { CopyIcon, Icon } from "@gluestack-ui/themed"

const SvgComponent = () => {
  return (
    <Icon color="white" as={CopyIcon} size="lg"/>
  );
};

export default SvgComponent;
