
import { Box, GluestackUIProvider, Text, Button, ButtonText } from '@gluestack-ui/themed';
import Gradient from '../assets/Icons/Gradient';
import { ScrollView, TouchableOpacity } from 'react-native';
import React, { useState, useEffect } from 'react';
import FeatureCard from '../componentes/FeatureCard';

import DocumentData from '../assets/Icons/DocumentData';
import Bell from '../assets/Icons/Bell';
import Mail from '../assets/Icons/Mail';
import Logo from '../assets/Icons/Logo';
import ReciboIcon from '../assets/Icons/ReciboIcon';
import Calendar from '../assets/Icons/Calendar';
import Reglamento from '../assets/Icons/Reglamento';

import UnidadCard from '../componentes/UnidadCard';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Unidad = (props) => {

  const volver = () => {
     props.setPage('unidades');
   }

   const [unidadData, setUnidadData] = useState([]);

    const loadUnidadData = async () => {
      const unidadData = await AsyncStorage.getItem('unidad');
      setUnidadData(JSON.parse(unidadData));
    }

    useEffect(() => {
      loadUnidadData();
    }, []);

  return (
    <Box flex={1} backgroundColor="$black">
    <ScrollView
      style={{ height: '100%' }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <Box
        position="absolute"
        sx={{
          '@base': {
            h: 500,
            w: 500,
          },
          '@lg': {
            h: 700,
            w: 700,
          },
        }}
      >
        <Gradient />
      </Box>
      <Box
        height="60%"
        sx={{
          '@base': {
            my: '$16',
            mx: '$5',
            height: '80%',
          },
          '@lg': {
            my: '$24',
            mx: '$32',
          },
        }}
        justifyContent="space-between"
        alignItems="center"
      >
      
        
        <Box
          justifyContent="center"
          alignItems="center">

          <Text color="$white" fontWeight="$normal">
            Unidad Seleccionada
          </Text>

                  <UnidadCard
               name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
               desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
               saldo={unidadData?.saldo}
             />
        
        </Box>

        <Box
          sx={{
            '@base': {
              flexDirection: 'column',
            },
            '@md': {
              flexDirection: 'row',
            },
          }}
        >

          <Text color="$white" fontWeight="$normal" textAlign='center'>
            Seleccione una opción
          </Text>

        </Box>

        <Box
          sx={{
            '@base': {
              flexDirection: 'column',
            },
      
          }}
        >

          <TouchableOpacity onPress={() => {props.setPage('expensas')}}>
          <FeatureCard
            iconSvg={DocumentData}
            name="Mis Expensas"
            desc="Consulta tus expensas desde la app."
          />
          </TouchableOpacity>
          

          <TouchableOpacity onPress={() => {props.setPage('proveedores')}}>
          <FeatureCard
            iconSvg={ReciboIcon}
            name="Proveedores"
            desc="Consulte los comprobantes de los proveedores."
          />
          </TouchableOpacity>


          <TouchableOpacity onPress={() => {props.setPage('notificaciones')}}>
          <FeatureCard
            iconSvg={Bell}
            name="Notificaciones"
            desc="Consulta las novedades de tu consorcio."
          />
          </TouchableOpacity>

        <TouchableOpacity onPress={() => {props.setPage('avisoPago')}}>
          <FeatureCard
            iconSvg={Mail}
            name="Aviso de Pago"
            desc="Envia tu aviso de pago desde la app."
          />
          </TouchableOpacity>
      
        <TouchableOpacity onPress={() => {props.setPage('amenities')}}>
          <FeatureCard
            iconSvg={Calendar}
            name="Amenities"
            desc="Reservas de amenities."
          />
          </TouchableOpacity>

          <TouchableOpacity onPress={() => {props.setPage('reglamento')}}>
          <FeatureCard
            iconSvg={Reglamento}
            name="Reglamento de Copropiedad"
            desc="Descargue el reglamento de copropiedad."
          />
          </TouchableOpacity>
      
        </Box>

    
        <Box
          sx={{
            '@base': {
              flexDirection: 'column',
            },
            '@md': {
              flexDirection: 'row',
            },
          }}
        >
    
          <Button
            mt={25}
            size="md"
            variant="outline"
            action="primary"

            title="Salir" onPress={() => volver()}>

            <ButtonText color="$white">Cambiar Unidad</ButtonText>
          </Button>

        </Box>
      </Box>
    </ScrollView>
  </Box>


    
  
  );
}

export default Unidad;