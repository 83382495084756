import { Box, GluestackUIProvider } from '@gluestack-ui/themed';
import { StyleSheet, View,Text, Image, FlatList } from 'react-native';
import dayjs from "dayjs";


const Calendar = (props) => {

  const renderClassItem = ({ item }) => (
    <View style={styles.classItem}>
      <View style={styles.timelineContainer}>
        <View style={styles.timelineLine} />
      </View>
      <View style={styles.classContent}>
        <View style={[styles.card, { backgroundColor: "rgba('222,222,222,0.1')" }]}>
          <Text style={styles.cardTitle}>UF: {item.unidad_id == null ? <Text>Externo</Text> : <Text> {item.unidad_sin_scope.uf} - {item.unidad_sin_scope.piso} - {item.unidad_sin_scope.departamento}</Text>} </Text>
          <Text style={styles.cardTitle}>Nombre: {item.nombre} </Text>
          <Text style={styles.cardDate}>Desde: {item.fecha_inicio}</Text>
          <Text style={styles.cardDate}>Hasta: {item.fecha_fin}</Text>
        </View>
      </View>
    </View>
  );

  const renderHeader = () => (
    <View style={styles.card}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{props?.amenitieData.aviso}</Text>
      </View>
    </View>
  )

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{props?.amenitieData.nombre}</Text>
      <FlatList
        contentContainerStyle={{ paddingHorizontal: 16, }}
        data={props.calendarData}
        ListHeaderComponent={renderHeader}
        renderItem={renderClassItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};
export default Calendar;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 4,
    marginLeft: 16,
    color: '#ffffff',
    textAlign: 'center',
  },
  card: {
    flex: 1,
    backgroundColor: '#000000',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 4,
    marginBottom: 4,
    padding: 8,
    borderColor: '#ffffff'
  },
  header: {
    marginBottom: 8,
  },
  headerTitle: {
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: 12,
    color: '#ffffff',
  },
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 8,
  },
  userInfo: {
    flex: 1,
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  userRole: {
    fontSize: 12,
    color: '#ffffff',
  },
  classItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  timelineContainer: {
    width: 30,
    alignItems: 'center',
  },
  timelineDot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#ff7f50',
    marginBottom: 8,
  },
  timelineLine: {
    flex: 1,
    width: 2,
    backgroundColor: '#ff7f50',
  },
  classContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    color: '#ffffff',
    width: 300,
    borderColor: '#525252',
    borderWidth: 1,
    borderRadius: 10,
  },
  classHours: {
    marginRight: 8,
    alignItems: 'flex-end',
    color: '#ffffff',
  },
  startTime: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  endTime: {
    fontSize: 16,
  },
  cardTitle: {
    fontSize: 16,
    color: '#ffffff',
    marginBottom: 4,
  },
  cardDate: {
    fontSize: 12,
    color: '#ffffff',
    marginBottom: 8,
  },
  studentListContainer: {
    marginRight: 10,
  },
  studentAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginLeft: -3,
    borderWidth: 1,
    borderColor: '#fff'
  },
});