
import { Box, GluestackUIProvider, Text, Button, ButtonText, Input, InputField, FormControlLabel, FormControlLabelText, FormControl, CalendarDaysIcon } from '@gluestack-ui/themed';
import Gradient from '../assets/Icons/Gradient';
import { ScrollView, View, ActivityIndicator } from 'react-native';
import React, { useState, useEffect, useContext } from 'react';
import UnidadCard from '../componentes/UnidadCard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DatePickerModal } from 'react-native-paper-dates'
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import dayjs from "dayjs";
import baseConfig from '../config';
import { es, registerTranslation } from 'react-native-paper-dates'
registerTranslation('es', es)

import * as WebBrowser from 'expo-web-browser';

const Reglamento = (props) => {

    const toast = useToast();
    const axiosContext = useContext(AxiosContext);
    const authContext = useContext(AuthContext);

    const volver = () => {
        props.setPage('unidad');
    }

    const [unidadData, setUnidadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reglamentoData, setReglamentoData] = useState('');

    const loadUnidadData = async () => {
        const unidadData = await AsyncStorage.getItem('unidad');
        setUnidadData(JSON.parse(unidadData));
    }   

    const descargarReglamento = async () => {
           await WebBrowser.openBrowserAsync(reglamentoData);
    }

    const loadReglamentoData = async () => {

        const tenantName = await AsyncStorage.getItem('tenant');
        let url = baseConfig.pre + tenantName + baseConfig.apiURL + '/reglamento/descargar';

        const data = {
            'unidad_id': unidadData.id,
        }

        try {

            const response = await axiosContext.authAxios.post(url, data);

            console.log(response.data);

            if (response.data.success == true) {
                const data = response.data;
                if (data.url) {
                    setReglamentoData(data.url);
                  }else{
                    setReglamentoData('');
                  }
            }else{
                setReglamentoData('');
            }
            
        } catch (error) {
            toast.show('No se pudo procesar la solicitud, intentalo mas tarde', {
                type: "warning",
            });
            console.log(error);
        }
        finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        loadUnidadData();
    }, []);

    
    useEffect(() => {
        setLoading(true);
        loadReglamentoData();
    }, [unidadData]);

    return (
        <Box flex={1} backgroundColor="$black">
            <ScrollView
                style={{ height: '100%' }}
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <Box
                    position="absolute"
                    sx={{
                        '@base': {
                            h: 500,
                            w: 500,
                        },
                        '@lg': {
                            h: 700,
                            w: 700,
                        },
                    }}
                >
                    <Gradient />
                </Box>
                <Box
                    height="60%"
                    sx={{
                        '@base': {
                            my: '$16',
                            mx: '$5',
                            height: '80%',
                        },
                        '@lg': {
                            my: '$24',
                            mx: '$32',
                        },
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >


                    <Box
                        justifyContent="center"
                        alignItems="center">

                        <Text color="$white" fontWeight="$normal">
                            Reglamento de copropiedad
                        </Text>

                        <UnidadCard
                            name={unidadData?.piso + ' ' + unidadData?.departamento + ' - UF ' + unidadData?.uf?.toString().padStart(3, '0')}
                            desc={unidadData?.edificio_direccion + ', ' + unidadData?.edificio_localidad}
                            saldo={unidadData?.saldo}
                        />

                        {loading ? <ActivityIndicator size="large" color="#ca50b0" /> : null}

                        {loading ? null :
                            <Box
                                justifyContent="center"
                                alignItems="center">
                                <Text color="$white" fontWeight="$normal" textAlign='center'>
                                    Pulse descargar para obtener el reglamento de copropiedad.
                                </Text>


                                <Box h="$32" w="$72" mt={15}>

                                    {reglamentoData == '' ?
                                      <Button
                                      size="md"
                                      variant="outline"
                                      action="primary"
                                      title="No disponible">
                                      <ButtonText color="$white">Reglamento aún no disponible</ButtonText>
                                  </Button>
                                        :
                                        <Button 
                                        size="md"
                                        variant="outline"
                                        action="primary"
                                        title="Descargar reglamento" onPress={() => { descargarReglamento() }}>
                                        <ButtonText color="$white">Descargar</ButtonText>
                                    </Button>
                                    }
                             
                                </Box>
                            </Box>
                        }

                    </Box>

                    <Box
                        sx={{
                            '@base': {
                                flexDirection: 'column',
                            },
                            '@md': {
                                flexDirection: 'row',
                            },
                        }}
                    >

                        <Button
                            mt={25}
                            size="md"
                            variant="outline"
                            action="primary"

                            title="Salir" onPress={() => volver()}>

                            <ButtonText color="$white">Volver</ButtonText>
                        </Button>

                    </Box>
                </Box>


            </ScrollView>
        </Box>




    );
}

export default Reglamento;